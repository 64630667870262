import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

function Footer({ setScrollToHostesses }) {

  const { pathname } = useLocation();

  function scrollToTop() {
    setScrollToHostesses(false);
    window.scrollTo(0, 0);
  }
  function scrollToHostesses() {
    setScrollToHostesses(true);
  }
  function scrollToSection() {
    window.scrollTo(0, document.getElementById('section-hostesses').getBoundingClientRect().y + window.scrollY - document.getElementById("header").offsetHeight);
  }

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <footer className='text-dark text-center p-3'>
      <Container>
        <div>
          <NavLink className="text-dark text-decoration-none px-1" to={"/accueil"} onClick={() => scrollToTop()}>Accueil</NavLink>
          <NavLink className="text-dark text-decoration-none px-1" to={"/accueil"} onClick={pathname === "/accueil" ? scrollToSection : scrollToHostesses}>Hôtesses</NavLink>
        </div>
        <div>
          <span>Sites partenaires :
            <br />
            <a className="link" href="https://rosetelephone.fr/" target="_blank" rel="noreferrer">rosetelephone.fr</a>
            <br />
            <a className="link" href="https://maitresses-telephone-rose.com/" target="_blank" rel="noreferrer">maitresses-telephone-rose.com</a>
            <br />
            <a className="link" href="https://telrose-visio.fr/" target="_blank" rel="noreferrer">telrose-visio.fr</a>
          </span>
        </div>
        <div>
          <small><NavLink className="text-dark pe-1" to={"/mentions-legales"} caseSensitive end>Mentions légales</NavLink></small>
        </div>
        <small className="text-dark">Copyright &copy; 2024 allodial.fr. Tous droits réservés.</small>
      </Container>
    </footer>
  )
}

export default Footer;